import React from "react";

import { graphql } from "gatsby";
import BlockContent from "../components/BlockContent";
import CookieBanner from "../components/CookieBanner";
import Cta from "../components/Cta";
import Footer from "../components/Footer";
import Nav from "../components/Nav";
import Seo from "../components/Seo";

const TestimonialPage = ({
  data: {
    sanityTestimonial: { highlight, story, description },
  },
}) => {
  return (
    <>
      <Seo title={"Historie fra" + description} ogImage={null} />
      <Nav />
      <article>
        <div className="max-w-screen-md mx-auto mt-16">
          <p>Historie fra:</p>
          <h1 className="text-heading-3 mb-4">{description}</h1>
          <BlockContent blocks={story} prose />
        </div>
      </article>
      <CookieBanner />
      <Footer />
    </>
  );
};

export const query = graphql`
  query TestimonialQuery($id: String) {
    sanityTestimonial(_id: { eq: $id }) {
      story: _rawStory
      description
    }
  }
`;

export default TestimonialPage;
